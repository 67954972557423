import React, {
  ChangeEvent,
  FocusEventHandler,
  ReactChild,
  useState,
} from "react";
import classnames from "classnames";

interface Props {
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onBlur?: FocusEventHandler;
  className?: string;
  name: string;
  label: string | ReactChild;
}

export const FileInput: React.FC<Props> = ({
  onChange,
  onBlur,
  className,
  label,
  name,
}) => {
  const [preview, setPreview] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPreview(e.target.files?.[0]?.name || "");
    onChange(e);
  };

  return (
    <label
      className={classnames(
        className,
        "border border-light-green border-dashed rounded-xl relative cursor-pointer group hover:border-green transition duration-200"
      )}
    >
      <div className="absolute left-0 right-0 flex items-center justify-center text-center bottom-0 top-0 text-gray text-sm group-hover:text-green">
        <span>{preview || label}</span>
      </div>
      <input
        className="py-6 w-full invisible"
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        type="file"
      />
    </label>
  );
};
