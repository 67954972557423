import React from "react";
import classnames from "classnames";

interface Props {
  height: number;
  width: number;
  src: string;
  className?: string;
}

export const ResponsiveIframe: React.FC<Props> = ({
  height,
  width,
  src,
  className,
}) => {
  return (
    <span className={classnames(className, "block")}>
      <span
        className="responsive-iframe"
        style={{ paddingTop: (height / width) * 100 + "%" }}
      >
        <iframe
          src={src}
          frameBorder="0"
          allow="picture-in-picture"
          allowFullScreen
        ></iframe>
      </span>
    </span>
  );
};
