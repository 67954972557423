import React, { useState } from "react";
import classnames from "classnames";
import { Argument } from "classnames";
import { Form, FormLayout, FormProps } from ".";
import { ContentfulRichText, PhoneNumberButton } from "../content";

type Props = {
  className?: Argument;
  layout?: FormLayout;
  title?: string;
  description?:
    | {
        raw: string;
      }
    | string;
  showPhoneNumber?: boolean;
  successTitle?: string;
} & FormProps;

export const FormContainer: React.FC<Props> = ({
  layout = FormLayout.HORIZONTAL,
  className,
  title,
  description,
  fields,
  name,
  submitText,
  successId,
  showPhoneNumber = false,
  submitIcon,
  successText,
  successTitle,
  showFillAgainButton,
}) => {
  const [success, setSuccess] = useState(false);
  const isVerticalLayout = layout === FormLayout.VERTICAL;

  const showSuccessState = success && successTitle;

  return (
    <div
      data-successid={successId}
      className={classnames(
        "shadow-md rounded-2xl overflow-hidden relative bg-white w-full",
        className,
        isVerticalLayout ? "flex flex-col" : "md:w-full md:grid md:grid-cols-3"
      )}
    >
      <div className="text-white py-6 px-4 md:pl-6 md:pr-8 lg:pr-10 lg:pl-10 relative col-span-1">
        <div
          className={classnames(
            "bg-light-green absolute w-full h-full left-0 top-0 bg-gradient-to-tr from-blue to-light-green",
            isVerticalLayout ? "" : "md:transform md:-skew-x-12 md:-ml-8"
          )}
        ></div>
        <div
          className={classnames(
            "flex flex-col justify-center h-full text-center",
            isVerticalLayout ? "" : "md:text-left md:pr-2",
            !description && "py-8"
          )}
        >
          <h2 className="text-2xl md:text-lg lg:text-2xl font-bold relative leading-6">
            {showSuccessState ? successTitle : title}
          </h2>
          {description && !showSuccessState && (
            <span className="block mt-4 relative text-sm">
              {typeof description === "string" ? (
                description
              ) : (
                <ContentfulRichText
                  content={description}
                  customRenderer={false}
                />
              )}
            </span>
          )}
          {showPhoneNumber && <PhoneNumberButton />}
        </div>
      </div>
      <div
        className={classnames(
          "col-span-2 py-6 px-4 lg:px-8",
          isVerticalLayout
            ? "flex-grow"
            : "md:px-12 md:pl-0 lg:pl-6 md:pr-6 lg:pr-12"
        )}
      >
        <Form
          fields={fields}
          name={name}
          layout={layout}
          submitText={submitText}
          successId={successId}
          submitIcon={submitIcon}
          successText={successText}
          onSubmit={() => setSuccess(true)}
          showFillAgainButton={showFillAgainButton}
          onReset={() => setSuccess(false)}
        />
      </div>
    </div>
  );
};
