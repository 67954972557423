/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { getSrc } from "gatsby-plugin-image";
import { GatsbyImageType } from "../..";

type Meta = {
  name?: string;
  property?: string;
  content: string;
};

type Props = {
  description?: string;
  lang?: string;
  meta?: Meta[];
  title?: string;
  keywords?: string;
  image?: GatsbyImageType;
};

export const BaseSeo: React.FC<Props> = ({
  description = "",
  lang = "et",
  meta = [],
  title = "",
  keywords = "Timber",
  image,
}) => {
  const getImageUrl = () => {
    return image?.gatsbyImageData ? getSrc(image.gatsbyImageData) : undefined;
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: "website",
        },
        {
          property: `og:image`,
          content: getImageUrl(),
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]
        .filter(item => !!item.content)
        .concat(meta as any[])}
    />
  );
};
