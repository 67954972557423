import React, {
  ChangeEvent,
  ElementType,
  FocusEventHandler,
  ReactChild,
  useState,
} from "react";
import classnames from "classnames";

interface Props {
  value: string;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  onBlur?: FocusEventHandler;
  className?: string;
  name: string;
  label: string | ReactChild;
  icon?: ElementType;
  error?: string;
}

export const TextInput: React.FC<Props> = ({
  value,
  onChange = () => {},
  onBlur,
  className,
  name,
  label,
  icon: Icon,
  error,
}) => {
  const [isInFocus, setIsInFocus] = useState(false);

  const handleBlur: FocusEventHandler = e => {
    onBlur?.(e);
    setIsInFocus(false);
  };

  return (
    <label className={classnames(className, "relative group")}>
      <span
        className={classnames(
          "absolute transition-all duration-200 ease-in-out group-hover:text-green",
          isInFocus || value ? "-mt-1 text-xs text-gray" : "mt-4 text-sm",
          Icon ? "ml-10" : ""
        )}
      >
        {label}
      </span>
      {Icon && (
        <span className="absolute mt-4 text-xl ml-2 group-hover:text-light-green transition duration-200">
          <Icon />
        </span>
      )}
      <input
        onFocus={() => setIsInFocus(true)}
        onBlur={handleBlur}
        name={name}
        value={value}
        onChange={onChange}
        type="text"
        className={classnames(
          "w-full outline-none border-b  h-12 pt-2",
          error
            ? "border-red-500"
            : "border-green-gray focus:border-light-green group-hover:border-green-gray",
          Icon ? "pl-10" : ""
        )}
      />
    </label>
  );
};
