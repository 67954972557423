import React, { ReactNode } from "react";
import Markdown from "markdown-to-jsx";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { RenderNode } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Block, Text } from "@contentful/rich-text-types";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import classnames from "classnames";
import Zoom from "react-medium-image-zoom";
import getYouTubeID from "get-youtube-id";

import { ContactForm } from "../forms";
import { Paragraph, ResponsiveIframe } from ".";
import { GatsbyImageType } from "../../typings.d";
import { createSlug, getPlainTextFromHeader } from "../../utils";

type Props = {
  content?: {
    raw: string;
    references?: (
      | {
          description?: string;
          __typename: string;
          contentful_id: string;
          file?: {
            url: string;
          };
        }
      | GatsbyImageType
    )[];
  };
  className?: string;
  colorClass?: string;
  customRenderer?: boolean;
  showImageDescriptions?: boolean;
  renderOptions?: RenderNode;
  wrapper?: string;
  enableImageZoom?: boolean;
  [key: string]: any;
};

const getIdForHeader = (node: Block) => {
  return createSlug(getPlainTextFromHeader(node.content as Text[]));
};

export const ContentfulRichText: React.FC<Props> = ({
  content,
  className,
  colorClass,
  customRenderer = true,
  showImageDescriptions = true,
  enableImageZoom = false,
  renderOptions = {},
  wrapper = "div",
  ...rest
}) => {
  const ParagraphRenderer = (node: Block, children: ReactNode) => {
    return <Paragraph colorClass={colorClass}>{children}</Paragraph>;
  };

  const H3Renderer = (node: Block, children: ReactNode) => {
    return (
      <h3
        className="text-green text-2xl font-semibold mt-16 mb-8"
        id={getIdForHeader(node)}
      >
        {children}
      </h3>
    );
  };

  const H2Renderer = (node: Block, children: ReactNode) => {
    return <h2 id={getIdForHeader(node)}>{children}</h2>;
  };

  const H1Renderer = (node: Block, children: ReactNode) => {
    return <h1 id={getIdForHeader(node)}>{children}</h1>;
  };

  const AssetRenderer = (node: Block) => {
    const target: GatsbyImageType = node?.data?.target;

    // image uploaded to contentful
    if (target?.gatsbyImageData) {
      const file: IGatsbyImageData = target.gatsbyImageData;
      const isPortrait = file.height > file.width;

      return (
        <div
          className={
            isPortrait ? "gatsby-image-portrait" : "gatsby-image-landscape"
          }
        >
          {enableImageZoom ? (
            <Zoom>
              <GatsbyImage image={file} alt={target.title} itemProp="image" />
            </Zoom>
          ) : (
            <GatsbyImage image={file} alt={target.title} itemProp="image" />
          )}
          {!!(target.description && showImageDescriptions) && (
            <figcaption
              className={classnames(
                "p-4 bg-very-light-gray text-green rounded-lg mt-2 mb-2",
                isPortrait ? "text-center" : ""
              )}
            >
              {target.description}
            </figcaption>
          )}
        </div>
      );
    }

    // file uploaded to contentful
    if (node.data.target.file.url) {
      return AssetHyperlinkRenderer(node, target.description);
    }
  };

  const AssetHyperlinkRenderer = (node: Block, children: ReactNode) => {
    return (
      <a href={node.data.target.file.url} className="text-green">
        {children}
      </a>
    );
  };

  const EmbeddedEntryRenderer = (node: Block) => {
    if (node.data.target?.__typename === "ContentfulContactForm") {
      return <ContactForm {...node.data.target} />;
    }

    if (node.data.target?.__typename === "ContentfulTabel") {
      return <Markdown>{node.data.target.content.content}</Markdown>;
    }
  };

  const AnchorRenderer = (node: Block, children: ReactNode) => {
    if (isYoutubeEmbed(node.data.uri)) {
      return (
        <ResponsiveIframe
          src={getYoutubeEmbedUrl(node.data.uri)}
          width={560}
          height={315}
        />
      );
    }

    const internal = isInternalUrl(node.data.uri);
    return (
      /* eslint-disable-next-line react/jsx-no-target-blank */
      <a
        className="text-green"
        target={internal ? "_self" : "_blank"}
        rel={internal ? undefined : "nofollow noopener noreferrer"}
        href={node.data.uri}
      >
        {children}
      </a>
    );
  };

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: ParagraphRenderer,
      [BLOCKS.HEADING_1]: H1Renderer,
      [BLOCKS.HEADING_2]: H2Renderer,
      [BLOCKS.HEADING_3]: H3Renderer,
      [BLOCKS.EMBEDDED_ASSET]: AssetRenderer,
      hyperlink: AnchorRenderer,
      "embedded-entry-inline": EmbeddedEntryRenderer,
      "embedded-entry-block": EmbeddedEntryRenderer,
      "asset-hyperlink": AssetHyperlinkRenderer,
      ...renderOptions,
    },
  };

  if (!content) {
    return null;
  }

  const Wrapper = wrapper as unknown as React.FC<{
    className?: string;
    children: React.ReactNode;
  }>;

  return (
    <Wrapper className={className} {...rest}>
      {/* @ts-expect-error dno */}
      {renderRichText(content, customRenderer ? options : {})}
    </Wrapper>
  );
};

const isInternalUrl = (uri: string) => {
  try {
    const url = new URL(uri);
    return url.hostname === "timber.ee" || !url.hostname;
  } catch (e) {
    return true;
  }
};

const isYoutubeEmbed = (uri: string) => {
  try {
    const url = new URL(uri);
    return url.hostname === "www.youtube.com";
  } catch (e) {
    return false;
  }
};

const getYoutubeEmbedUrl = (uri: string): string => {
  const id = getYouTubeID(uri);
  return `https://www.youtube.com/embed/${id}`;
};
