import React, { ChangeEvent, ElementType, ReactChild, useState } from "react";
import { Formik, FormikProps } from "formik";
import { MdMessage } from "react-icons/md";
import { BsChevronLeft } from "react-icons/bs";
import classnames, { Argument } from "classnames";
import * as Yup from "yup";
import { TextInput, Button, ButtonType, Checkbox, FileInput } from ".";
import { submitNetlifyForm } from "../../utils";
import { IconPosition } from "./Button";

type Field = {
  name: string;
  label: string | ReactChild;
  type: string;
  icon?: ElementType;
  initialValue?: any;
  schema?: any;
};

type Group = {
  label: string;
  fields: Field[];
};

type Fields = (Group | Field)[];

export enum FormLayout {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export type FormProps = {
  fields: Fields;
  className?: Argument;
  layout?: FormLayout;
  submitText?: string;
  name: string;
  successId?: string;
  submitIcon?: ReactChild;
  successText?: string;
  onSubmit?: () => void;
  onReset?: () => void;
  showFillAgainButton?: boolean;
};

export const Form: React.FC<FormProps> = ({
  fields,
  layout,
  submitText = "SAADA",
  name,
  successId,
  submitIcon = <MdMessage size={24} />,
  successText = "Saadetud!",
  onSubmit: onSubmitFromProps,
  showFillAgainButton,
  onReset: onResetFromProps,
}) => {
  const isVerticalLayout = layout === FormLayout.VERTICAL;
  const [success, setSuccess] = useState(false);
  const initialValues = arrayToObject(fields, "initialValue");
  const validationSchema = Yup.object().shape(arrayToObject(fields, "schema"));

  const onSubmit = async (values: Record<string, any>) => {
    await submitNetlifyForm(values, name);
    setSuccess(true);
    onSubmitFromProps?.();
  };

  const onReset = () => {
    setSuccess(false);
    onResetFromProps?.();
  };

  const getField = (
    field: Field | Group,
    {
      values,
      handleBlur,
      handleChange,
      setValues,
      errors,
      touched,
    }: Partial<FormikProps<typeof initialValues>>
  ) => {
    if (isGroup(field)) {
      return (
        <div
          key={field.label}
          className={classnames(
            "col-span-2 grid grid-cols-4 gap-y-4",
            isVerticalLayout ? "" : "md:col-span-1"
          )}
        >
          <div className="col-span-2 flex items-center">
            <span className="px-8 h-full rounded-full text-sm bg-green-gray text-green font-bold flex items-center">
              {field.label}
            </span>
          </div>
          {field.fields.map(childField =>
            getField(childField, {
              errors,
              values,
              handleBlur,
              handleChange,
              setValues,
              touched,
            })
          )}
        </div>
      );
    }

    const error = touched?.[field.name]
      ? (errors?.[field.name] as string)
      : undefined;

    if (field.type === "text") {
      return (
        <TextInput
          onBlur={handleBlur}
          key={field.name}
          name={field.name}
          label={field.label}
          value={values?.[field.name] || ""}
          error={error}
          onChange={handleChange}
          className={classnames(
            "col-span-2",
            isVerticalLayout ? "" : "md:col-span-1"
          )}
          icon={field.icon}
        />
      );
    }
    if (field.type === "checkbox") {
      return (
        <Checkbox
          onBlur={handleBlur}
          key={field.name}
          value={values?.[field.name]}
          onChange={handleChange}
          name={field.name}
          label={field.label}
          error={error}
          className={classnames(
            "col-span-2",
            isVerticalLayout ? "" : "md:col-span-1"
          )}
        />
      );
    }
    if (field.type === "file") {
      const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues?.({
          ...values,
          [field.name]: e.target.files?.[0],
        });
      };
      return (
        <FileInput
          onBlur={handleBlur}
          key={field.name}
          onChange={handleFileChange}
          name={field.name}
          label={field.label}
          className={classnames(
            "col-span-2",
            isVerticalLayout ? "" : "md:col-span-1"
          )}
        />
      );
    }
  };

  if (success) {
    return (
      <div
        className="h-full flex items-center flex-col justify-between"
        id={successId}
      >
        <div className="flex-grow text-2xl text-green font-bold text-center py-14">
          {successText}
        </div>

        {showFillAgainButton && (
          <Button
            block
            onClick={() => onReset()}
            type={ButtonType.DEFAULT}
            filled
            icon={<BsChevronLeft />}
            iconPosition={IconPosition.LEFT}
          >
            Täida uuesti
          </Button>
        )}
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setValues,
      }) => (
        <form
          onSubmit={handleSubmit}
          data-netlify="true"
          name={name}
          className="grid grid-cols-2 gap-y-4 gap-x-6"
        >
          <input type="hidden" name="form-name" value={name} />
          {fields.map(field =>
            getField(field, {
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setValues,
            })
          )}
          <Button
            block
            disabled={isSubmitting}
            className={classnames(
              "col-span-2",
              isVerticalLayout ? "" : "md:col-span-1"
            )}
            type={ButtonType.PRIMARY}
            icon={submitIcon}
          >
            {submitText}
          </Button>
        </form>
      )}
    </Formik>
  );
};

const arrayToObject = (arr: any[], field: string): Record<string, any> => {
  return arr.reduce((acc, current) => {
    if (current.fields) {
      return {
        ...acc,
        ...arrayToObject(current.fields, field),
      };
    }
    return {
      ...acc,
      [current.name]: current[field],
    };
  }, {});
};

const isGroup = (item: Field | Group): item is Group => {
  return typeof (item as Group).fields !== "undefined";
};
