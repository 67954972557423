import React, { ChangeEvent, FocusEventHandler, ReactChild } from "react";
import classnames from "classnames";
import { MdCheck } from "react-icons/md";

interface Props {
  value?: boolean;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  onBlur?: FocusEventHandler;
  className?: string;
  name: string;
  label: string | ReactChild;
  error?: string;
}

export const Checkbox: React.FC<Props> = ({
  value = false,
  onChange = () => {},
  onBlur,
  className,
  label,
  name,
  error,
}) => {
  return (
    <label
      className={classnames(
        className,
        "flex items-center cursor-pointer group"
      )}
    >
      <input
        type="checkbox"
        name={name}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        hidden
      />
      <div
        style={{ width: 36, height: 36 }}
        className={classnames(
          "rounded-full rounded-tr-none flex-shrink-0 mr-3 flex justify-center items-center text-white",
          error ? "bg-red-200" : "bg-green-gray"
        )}
      >
        <MdCheck
          className={classnames(
            "transition duration-200 text-green",
            !value && "opacity-0"
          )}
          size={20}
        />
      </div>
      <span
        className={classnames(
          "text-xs group-hover:text-green transition duration-200",
          error ? "text-red-700" : ""
        )}
      >
        {label}
      </span>
    </label>
  );
};
