import React, { ReactNode } from "react";
import classnames from "classnames";

interface Props {
  children: ReactNode;
  className?: string;
}
export const HighlightedSection: React.FC<Props> = ({
  children,
  className,
}) => (
  <section
    className={classnames("bg-very-light-gray py-8 md:py-16", className)}
  >
    <div className="container">{children}</div>
  </section>
);
