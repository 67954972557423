const encode = (data: Record<string, any>) => {
  const formData = new FormData();
  Object.keys(data).forEach(k => {
    formData.append(k, data[k]);
  });
  return formData;
};

export const submitNetlifyForm = (
  formValues: Record<string, string | boolean | number>,
  formName: string
): Promise<Response> => {
  return fetch("/", {
    method: "POST",
    body: encode({
      "form-name": formName,
      ...cleanValues(formValues),
    }),
  });
};

const cleanValues = (values: Record<string, string | boolean | number>) => {
  const mappedValues: Record<string, string | number> = {};
  Object.keys(values).forEach(key => {
    if (typeof values[key] === "boolean") {
      mappedValues[key] = values[key] ? "Jah" : "Ei";
    } else if (typeof values[key] === "string") {
      mappedValues[key] = (values[key] as string).trim();
    } else {
      mappedValues[key] = values[key] as number;
    }
  });

  return mappedValues;
};
