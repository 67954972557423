import React from "react";
import classnames from "classnames";
import { MdEmail, MdPhone } from "react-icons/md";
import { Button, ButtonShape } from "../forms";

export enum ContactInfoLayout {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}

const defaultDetails = {
  phone: {
    label: "Helista meile",
    value: "+372 666 50 50",
  },
  email: {
    label: "Saada email",
    value: "info@timber.ee",
  },
};

interface Props {
  className?: string;
  layout?: ContactInfoLayout;
  details?: {
    phone: {
      label: string;
      value: string;
    };
    email: {
      label: string;
      value: string;
    };
  };
  showButton?: boolean;
  href?: string;
}

export const ContactInfo: React.FC<Props> = ({
  className,
  layout = ContactInfoLayout.VERTICAL,
  details = defaultDetails,
  showButton = true,
  href = "/meist",
} = {}) => (
  <div
    className={classnames(
      "rounded-lg px-4 md:px-8 pb-4 pt-8 shadow-lg flex flex-col items-center bg-white relative",
      className
    )}
  >
    <div
      className={classnames(
        "grid w-full mb-4 gap-4 md:gap-6",
        layout === ContactInfoLayout.VERTICAL ? "grid-cols-2" : "lg:grid-cols-4"
      )}
    >
      <div className="col-span-1">
        <span className="block text-sm text-gray mb-2">
          {details.phone.label}
        </span>
        <span className="sm:text-xl xl:text-2xl flex items-center">
          <MdPhone className="hidden sm:block text-light-green mr-2 lg:text-3xl" />
          <strong>
            <a href={`tel:${details.phone.value}`}>{details.phone.value}</a>
          </strong>
        </span>
      </div>
      <div className="col-span-1">
        <span className="block text-sm text-gray mb-2">
          {details.email.label}
        </span>
        <span className="sm:text-xl xl:text-2xl flex items-center">
          <MdEmail className="hidden sm:block text-light-green mr-2 text-3xl flex-shrink-0" />
          <strong>
            <a href={`mailto:${details.email.value}`}>{details.email.value}</a>
          </strong>
        </span>
      </div>
      {showButton && (
        <div
          className={classnames(
            "col-span-2 flex items-center",
            layout === ContactInfoLayout.VERTICAL ? "" : "justify-end"
          )}
        >
          <Button
            shape={ButtonShape.ROUND}
            className={classnames(
              "xl:text-lg hidden sm:block",
              layout === ContactInfoLayout.VERTICAL
                ? "w-full"
                : "w-full lg:w-auto"
            )}
            filled
            href={href}
          >
            Jäta enda kontaktid, võtame Sinuga ühendust.
          </Button>
          <Button
            shape={ButtonShape.ROUND}
            className={classnames("text-lg sm:hidden")}
            filled
            block
            href={href}
          >
            Jäta enda kontaktid
          </Button>
        </div>
      )}
    </div>
  </div>
);
