import React, { ReactChild, MouseEvent, ReactNode } from "react";
import classnames from "classnames";
import { Link } from "gatsby";

export enum ButtonType {
  DEFAULT = "DEFAULT",
  PRIMARY = "PRIMARY",
}

export enum ButtonShape {
  RECTANGULAR = "RECTANGULAR",
  ROUND = "ROUND",
  CIRCLE = "CIRCLE",
}

export enum IconPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

interface Props {
  disabled?: boolean;
  children: ReactNode;
  type?: ButtonType;
  shape?: ButtonShape;
  className?: string;
  backgroundColorClass?: string;
  colorClass?: string;
  borderColorClass?: string;
  heightClass?: string;
  fontWeightClass?: string;
  icon?: ReactChild;
  href?: string;
  filled?: boolean;
  onClick?(event: MouseEvent): void;
  block?: boolean;
  noBorder?: boolean;
  iconPosition?: IconPosition;
  target?: "_blank";
  [key: string]: any;
}
export const Button: React.FC<Props> = ({
  disabled,
  children,
  type = ButtonType.DEFAULT,
  shape = ButtonShape.RECTANGULAR,
  className,
  backgroundColorClass,
  colorClass,
  borderColorClass,
  heightClass,
  fontWeightClass,
  icon,
  href,
  filled = type === ButtonType.PRIMARY,
  onClick = () => {},
  block,
  noBorder = false,
  iconPosition = IconPosition.RIGHT,
  target,
  ...rest
}) => {
  const getBackgroundColorClass = () => {
    if (backgroundColorClass) {
      return backgroundColorClass;
    }
    if (filled) {
      if (disabled) {
        return "bg-light-gray";
      }
      if (type === ButtonType.PRIMARY) {
        return "bg-primary hover:bg-light-primary";
      }
      return "bg-light-green hover:bg-primary";
    }
    if (type === ButtonType.PRIMARY) {
      return "hover:bg-light-primary";
    }
    return "hover:bg-light-green";
  };
  const getBorderColorClass = () => {
    if (borderColorClass) {
      return borderColorClass;
    }
    if (noBorder) {
      return "";
    }
    if (disabled) {
      return "border border-gray";
    }

    if (filled) {
      if (type === ButtonType.PRIMARY) {
        return "border border-primary hover:border-light-primary";
      }
      return "border border-light-green hover:border-primary";
    }
    return "border border-light-green hover:border-light-green";
  };
  const getColorClass = () => {
    if (colorClass) {
      return colorClass;
    }
    if (disabled) {
      return "text-gray";
    }
    if (filled) {
      return "text-white";
    }
    if (type === ButtonType.PRIMARY) {
      return "text-primary";
    }
    return "text-light-green hover:text-white";
  };
  const getHeightClass = () => {
    if (heightClass) {
      return heightClass;
    }
    return "h-12";
  };
  const getFontWeightClass = () => {
    if (fontWeightClass) {
      return fontWeightClass;
    }
    if (type === ButtonType.PRIMARY) {
      return "font-bold";
    }
    return "";
  };

  const shapeClass =
    shape === ButtonShape.RECTANGULAR ? "rounded-md" : "rounded-full";

  const paddingClass = shape === ButtonShape.CIRCLE ? "p-0" : "px-8";

  const widthClass = shape === ButtonShape.CIRCLE ? "w-12" : "";

  const handleClick = (e: MouseEvent) => {
    if (href) {
      return;
    }
    onClick(e);
  };

  const isLocalHref = href?.startsWith("/");
  const Element = href ? (isLocalHref ? Link : "a") : "button";

  const getHrefProp = () => {
    if (href && isLocalHref) {
      return {
        to: href,
      };
    }
    if (href) {
      const extra =
        target === "_blank"
          ? { target, rel: "nofollow noopener noreferrer" }
          : {};
      return { href, ...extra };
    }
    return {};
  };

  return (
    /* @ts-expect-error too complicated */
    <Element
      {...getHrefProp()}
      disabled={disabled}
      onClick={handleClick}
      className={classnames(
        getBackgroundColorClass(),
        getBorderColorClass(),
        getColorClass(),
        getFontWeightClass(),
        shapeClass,
        getHeightClass(),
        block ? "w-full" : "",
        paddingClass,
        widthClass,
        "relative inline-flex items-center justify-center transition",
        className
      )}
      {...rest}
    >
      {!!(icon && iconPosition === IconPosition.LEFT) && (
        <span
          className={classnames(
            block ? "absolute left-0 ml-4" : "inline-block mr-2 -ml-4"
          )}
        >
          {icon}
        </span>
      )}
      {href ? (
        <span className="flex items-center justify-center h-full text-center">
          {children}
        </span>
      ) : (
        children
      )}
      {!!(icon && iconPosition === IconPosition.RIGHT) && (
        <span
          className={classnames(
            block ? "absolute right-0 mr-4" : "inline-block ml-2 -mr-4"
          )}
        >
          {icon}
        </span>
      )}
    </Element>
  );
};
