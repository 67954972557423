import { Link } from "gatsby";
import React, { useState } from "react";
import { MdChevronRight, MdClose, MdMenu } from "react-icons/md";
import classnames from "classnames";
import { Button, ButtonType } from "timber-common";
import Hammer from "./hammer.svg";

export interface HeaderLink {
  label: string;
  url: string;
  children?: HeaderLink[];
  type?: "button";
}

type Props = {
  links: HeaderLink[];
  logo: React.ReactNode;
  showBorder?: boolean;
};

export const Header: React.FC<Props> = ({
  links,
  logo,
  showBorder = false,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const auctionsButton = (
    <Button
      href="https://oksjonid.timber.ee"
      type={ButtonType.DEFAULT}
      noBorder
      className="text-sm overflow-hidden pl-4 pr-4"
      colorClass="text-dark-primary"
      backgroundColorClass="bg-very-light-primary"
      fontWeightClass="font-semibold"
    >
      <img
        className="relative top-2 mr-2"
        src={Hammer}
        height="42"
        width="30"
        alt=""
      />
      Oksjonikeskkond
    </Button>
  );

  return (
    <header
      className={classnames(
        "py-2 lg:py-4 bg-white relative z-20",
        showBorder && "border-b border-green-gray"
      )}
    >
      <nav className="container flex justify-between relative px-4 xl:px-0 z-20">
        <div className="flex items-center">
          <Link to="/" className="text-5xl mr-4">
            {logo}
          </Link>
        </div>
        <div className="flex items-center">
          <ul
            className={classnames(
              "list-none justify-between lg:flex lg:visible absolute lg:static transition-all duration-200 bg-white right-0 left-0 top-full mx-6 lg:mx-2 p-4 lg:p-0",
              menuOpen
                ? "shadow-md lg:shadow-none rounded-md opacity-100 mt-2 lg:mt-0"
                : "invisible opacity-0 lg:opacity-100"
            )}
          >
            {links.map(link => (
              <li
                key={link.label}
                className={classnames(
                  "mx-3 relative timber-nav-item my-4 lg:my-0"
                )}
              >
                {link.type === "button" ? (
                  <Button
                    href={link.url}
                    filled
                    className="text-sm pl-3 pr-3"
                    backgroundColorClass="bg-very-light-green"
                    colorClass="text-green"
                    noBorder
                  >
                    {link.label}
                  </Button>
                ) : (
                  <>
                    <Link
                      to={link.url}
                      className="text-gray lg:text-sm flex items-center h-full hover:text-green transition duration-100"
                      activeClassName="text-green"
                    >
                      <span className="timber-nav-item-target relative">
                        {link.label}
                      </span>
                      {link.children && (
                        <MdChevronRight className="inline-block transform rotate-90 text-lg invisible lg:visible ml-1" />
                      )}
                    </Link>
                    {link.children && (
                      <ul className="lg:left-1/2 lg:bg-white lg:absolute pl-4 lg:p-6 lg:shadow-md lg:rounded-md transition-all duration-200 ease-in-out lg:invisible opacity-100 lg:opacity-0 mb-6">
                        {link.children.map(childLink => (
                          <li
                            key={childLink.label}
                            className="lg:whitespace-nowrap mb-1"
                          >
                            <Link
                              to={childLink.url}
                              className="text-gray text-md hover:text-green transition duration-100"
                              activeClassName="text-green"
                            >
                              <span className="timber-nav-item-target relative">
                                {childLink.label}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </li>
            ))}
            <li className="lg:hidden ml-3 mt-4">{auctionsButton}</li>
          </ul>
          <button
            aria-label={menuOpen ? "Sulge menüü" : "Ava menüü"}
            onClick={() => setMenuOpen(!menuOpen)}
            className="outline-none lg:hidden"
          >
            {menuOpen ? (
              <MdClose className="text-4xl flex items-center" />
            ) : (
              <MdMenu className="text-4xl flex items-center" />
            )}
          </button>
          <div className="items-center hidden lg:flex">{auctionsButton}</div>
        </div>
      </nav>
    </header>
  );
};
