import { BLOCKS, Block, Text } from "@contentful/rich-text-types";
import { createSlug } from "./misc";

export const getHeadingsFromRichText = (block: Block) => {
  const headingTypes = [BLOCKS.HEADING_1, BLOCKS.HEADING_2, BLOCKS.HEADING_3];
  return (
    block.content
      .filter(block =>
        // @ts-expect-error hyperlink is missing from nodeType
        headingTypes.includes(block.nodeType)
      )
      // @ts-expect-error should always be Block
      .map((block: Block) => {
        const plainText = getPlainTextFromHeader(block.content as Array<Text>);
        return {
          slug: createSlug(plainText),
          text: plainText,
        };
      })
  );
};

export const getPlainTextFromHeader = (contentNode: Array<Text>) => {
  return contentNode.reduce((acc, current) => {
    return acc + current.value;
  }, "");
};
