import slugify from "slugify";

const getBackgroundImageUrl = (url: string): string => {
  return `url(${url})`;
};

export const getBackgroundImageStyle = (
  url: string
): { backgroundImage: string } => {
  return {
    backgroundImage: getBackgroundImageUrl(url),
  };
};

export const createSlug = (string: string): string => {
  return slugify(string, {
    lower: true,
  });
};
