import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

import classNames from "classnames";
import { FiChevronDown } from "react-icons/fi";

export type MenuItem = {
  id: string;
  label: string;
  url: string;
  children?: MenuItem[];
};

type Props = {
  items: MenuItem[];
  className?: string;
};

export const SidebarNav: React.FC<Props> = ({ items, className }) => {
  const uniqueParentItems = getUniqueParentItems(items);
  const location = useLocation();

  const findActiveParentId = () => {
    const item = uniqueParentItems.find(({ url }) => location.pathname === url);
    if (item) {
      return item.id;
    }
    const parent = uniqueParentItems.find(({ children }) =>
      children?.some(({ url }) => location.pathname === url)
    );

    if (parent) {
      return parent.id;
    }
  };

  const [activeParentId, setActiveParentId] = useState<string | undefined>(
    findActiveParentId
  );

  useEffect(() => {
    setActiveParentId(findActiveParentId());
  }, [location.pathname]);

  return (
    <nav className={classNames(className)}>
      <ul>
        {uniqueParentItems.map(item => (
          <li key={item.id} className="text-dark-gray">
            <Link
              className={classNames(
                "py-4 pl-3 pr-1 flex items-center border-b border-light-gray font-bold",
                {
                  "text-green": item.id === activeParentId,
                }
              )}
              activeClassName="bg-very-light-green rounded-md	border-0 text-green"
              to={item.url}
            >
              <span className="flex-grow">{item.label}</span>
              {!!item.children?.length && (
                <FiChevronDown className="text-3xl flex-shrink-0 pl-2" />
              )}
            </Link>
            {!!item.children?.length && activeParentId === item.id && (
              <>
                <ul>
                  {item.children.map(child => (
                    <li key={child.id} className="text-dark-gray">
                      <Link
                        className="py-2 pl-5 pr-1 block"
                        activeClassName="bg-very-light-green rounded-md	border-0 text-green"
                        to={child.url}
                      >
                        {child.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

const getUniqueParentItems = (items: MenuItem[]): MenuItem[] => {
  const childIds = items.reduce<string[]>((acc, current) => {
    return [...acc, ...(current.children || []).map(({ id }) => id)];
  }, []);

  return items.filter(({ id }) => !childIds.includes(id));
};
