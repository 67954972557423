import React from "react";
import classnames from "classnames";

interface Props {
  content: string;
  className?: string;
  style?: Record<string, string | number>;
}

export const ContentfulSvg: React.FC<Props> = ({
  content,
  className,
  ...props
}) => (
  <div
    {...props}
    className={classnames(className, "svg-full")}
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  ></div>
);
