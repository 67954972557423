import React from "react";

import image from "../../images/office.jpg";

type Props = {
  [key: string]: any;
};

export const Office: React.FC<Props> = ({ ...props } = {}) => {
  return <img {...props} src={image} />;
};
