import React from "react";
import { MdPhone } from "react-icons/md";
import { Button, ButtonShape, IconPosition } from "../forms";

export const PhoneNumberButton: React.FC = () => {
  return (
    <div>
      <Button
        shape={ButtonShape.ROUND}
        backgroundColorClass="bg-white"
        heightClass="h-10"
        className="font-bold mt-2"
        colorClass="text-green hover:text-light-green"
        icon={<MdPhone size="24" />}
        iconPosition={IconPosition.LEFT}
        href="tel:+372 666 50 50"
      >
        666 50 50
      </Button>
    </div>
  );
};
