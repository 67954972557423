import { getCookieConsentValue } from "react-cookie-consent";
import { loadClarity, loadFacebookPixel, loadGtag } from "./analytics";

export type AppName = "timber" | "metsayhistu";

export const handleInitialClientLoad = (appName: AppName) => {
  const hasAlreadyConsented =
    getCookieConsentValue("timber-cookie-consent") === "true";
  loadGtag(hasAlreadyConsented ? "granted" : "denied");
  if (hasAlreadyConsented) {
    loadAnalyticsProviders(appName);
  }
};

export const loadAnalyticsProviders = (appName: AppName) => {
  loadFacebookPixel();
  if (appName === "timber") {
    loadClarity();
  }
};
