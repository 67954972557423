import React from "react";
import { Button, ButtonShape, ContactInfo, ContactInfoLayout } from "../../";

export type FooterColumns = {
  title: string;
  items: {
    title: string;
    url: string;
    target?: string;
  }[];
}[];

type Props = {
  columns: FooterColumns;
  showContactForm?: boolean;
  contactHref?: string;
  contactDetails?: {
    phone: {
      label: string;
      value: string;
    };
    email: {
      label: string;
      value: string;
    };
  };
  logo: React.ReactNode;
  illustration: React.ReactNode;
  companyName: string;
};

export const Footer: React.FC<Props> = ({
  columns,
  showContactForm = true,
  contactHref,
  contactDetails,
  logo,
  illustration,
  companyName,
}) => {
  const getItemUrl = (item: { title: string; url: string }) => {
    return item.url;
  };
  return (
    <footer>
      {showContactForm && (
        <div className="bg-green">
          <div className="container py-10">
            <ContactInfo
              layout={ContactInfoLayout.HORIZONTAL}
              href={contactHref}
              details={contactDetails}
            />
          </div>
        </div>
      )}
      <div className="bg-very-dark-green">
        <div className="container">
          <div className="flex flex-wrap md:flex-nowrap pt-10">
            <div className="lg:mr-16 xl:mr-24 flex-shrink-0	w-full lg:w-auto md:hidden lg:flex flex-col">
              <span className="flex justify-center">{logo}</span>
              <div className="flex-grow relative">{illustration}</div>
            </div>
            <div className="w-full flex flex-col justify-between mt-8 md:mt-0">
              <div className="sm:flex justify-between flex-wrap">
                {columns.map(col => (
                  <ul
                    className="col-span-1 mr-4 mb-6 text-center md:text-left"
                    key={col.title}
                  >
                    <li className="mb-2 md:mb-6">
                      <span className="text-light-green font-bold">
                        {col.title}
                      </span>
                    </li>
                    {col.items.map(item => (
                      <li key={item.title} className="mb-2 font-thin">
                        <a
                          className="text-white hover:text-light-green"
                          href={getItemUrl(item)}
                          target={item.target}
                        >
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
              <div className="text-light-green md:flex flex-wrap justify-between items-center mt-10 mb-10 text-center md:text-left">
                <span>
                  Copyright {new Date().getFullYear()} © {companyName}
                </span>
                <br className="md:hidden" />
                <span>Narva mnt 36 </span>
                <span>10120 </span>
                <span>Tallinn </span>
                <span>Eesti</span>
                <div className="block md:inline-block">
                  <Button
                    shape={ButtonShape.ROUND}
                    href="/"
                    colorClass="text-white"
                    heightClass="h-10"
                    className="mt-4 md:mt-0 md:w-auto"
                  >
                    Privaatsuspoliitika
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
