import React from "react";
import { MdEmail, MdMap, MdPerson, MdPhone } from "react-icons/md";
import classnames from "classnames";
import { FormContainer } from "./FormContainer";
import * as Yup from "yup";
import { FormLayout } from ".";
import { ContactInfo } from "../content";

const fields = [
  {
    name: "name",
    label: "Sinu Nimi",
    type: "text",
    icon: MdPerson,
    initialValue: "",
    schema: Yup.string().min(2).required(),
  },
  {
    name: "phone",
    label: "Telefoninumber",
    type: "text",
    icon: MdPhone,
    initialValue: "",
    schema: Yup.string().min(7).required(),
  },
  {
    name: "email",
    label: "E-mail",
    type: "text",
    icon: MdEmail,
    initialValue: "",
    schema: Yup.string().trim().email().required(),
  },
  {
    name: "cadastre",
    label: (
      <>
        Katastrinumber <em>Valikuline</em>
      </>
    ),
    type: "text",
    icon: MdMap,
    initialValue: "",
    schema: Yup.string(),
  },
  {
    name: "agreement",
    label:
      "Olen nõus, et AS Timber vaatab minu andmeid ja võtab minuga ühendust.",
    type: "checkbox",
    initialValue: true,
    schema: Yup.bool().oneOf([true]).required(),
  },
];

type Props = {
  className?: string;
  id?: string;
  layout?: FormLayout;
  title?: string;
  description?:
    | {
        raw: string;
      }
    | string;
  paragraph?: {
    // duplicates description to match contentful model
    raw: string;
  };
  collapseInMobile?: boolean;
  contentPage?: string;
  order?: number;
  successId?: string;
  showPhoneNumber?: boolean;
  showFillAgainButton?: boolean;
};

export const ContactForm: React.FC<Props> = ({
  className,
  id,
  layout = FormLayout.HORIZONTAL,
  title = "Jäta enda andmed, vaatame su metsa üle!",
  description,
  paragraph,
  collapseInMobile = false,
  contentPage,
  order,
  successId = "contactForm-success",
  showPhoneNumber = false,
  showFillAgainButton = false,
}) => {
  const getFormName = () => {
    return `contactForm ${[contentPage, order].join("-")}`.trim();
  };
  return (
    <div className={className} id={id}>
      {collapseInMobile && <ContactInfo className="md:hidden" />}
      <FormContainer
        className={classnames(collapseInMobile && "hidden md:block", "h-full")}
        name={getFormName()}
        fields={fields}
        layout={layout}
        title={title}
        description={description || paragraph}
        successId={successId}
        showPhoneNumber={showPhoneNumber}
        successTitle="Täname!"
        successText="Võtame teiega peagi ühendust."
        showFillAgainButton={showFillAgainButton}
      />
    </div>
  );
};
