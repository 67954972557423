import React, { ReactChild } from "react";
import classnames from "classnames";

interface Props {
  children: ReactChild | ReactChild[];
  tag?: "h1" | "h2" | "h3";
  className?: string;
  colorClass?: string;
  fontSizeClass?: string;
  id?: string;
  [key: string]: any;
}

export const BlockTitle: React.FC<Props> = ({
  children,
  tag: Tag = "h2",
  colorClass = "text-green",
  fontSizeClass = "text-2xl lg:text-4xl",
  className,
  id,
  ...rest
}) => {
  return (
    <Tag
      id={id}
      style={{ lineHeight: "1.3em" }}
      className={classnames(
        "font-bold mb-6",
        fontSizeClass,
        className,
        colorClass
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};
