import React, { ReactNode } from "react";
import classnames from "classnames";

interface Props {
  as?: keyof JSX.IntrinsicElements;
  children: ReactNode | ReactNode[];
  className?: string;
  colorClass?: string;
}

export const Paragraph: React.FC<Props> = ({
  children,
  className,
  colorClass = "text-dark-gray",
  as: Tag = "p",
}: Props) => {
  return (
    <Tag className={classnames("my-4", colorClass, className)}>{children}</Tag>
  );
};
